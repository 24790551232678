$('#website_scope_select_id').on('change', function () {
    let data = [
        $(this).val(),
    ];

    $.ajax({
        url: $('#website_scope_select').data('href'),
        method: 'POST',
        data: data,
        cache: false,
        processData: false,
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        complete: function (result) {
        },
        error: function (result) {
            alert("Es ist ein Fehler beim Wechsel der Website aufgetreten!");
        },
        success: function (result) {
            location.reload();
        }
    });
});
