$('body').on('submit', 'form', function (e) {
    if ($(this).hasClass('ajax-form')) {
        e.preventDefault();
        let self = this;
        let submitButton = $(self).find('button[type=submit]').first();
        let oldSubmitMessage = submitButton.val() ? submitButton.val() : submitButton.text();
        if (submitButton) {
            submitButton.html(
                '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Lädt...'
            );
        }
        $('.form-control').removeClass('is-invalid');
        let action = $(self).attr('action');
        let formData = new FormData(this);
        let method = $(self).attr('method');
        if (!method) {
            method = "post";
        }
        $.ajax({
            url: action,
            method: method,
            data: formData,
            cache: false,
            contentType: false,
            processData: false,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            complete: function (result) {
                if (submitButton) {
                    submitButton.val(oldSubmitMessage).text(oldSubmitMessage);
                }
            },
            error: function (result) {
                let data = result.responseJSON;
                let errors = data.errors;

                if (!jQuery.isEmptyObject(errors)) {
                    for (let field in errors) {
                        if (!errors.hasOwnProperty(field)) continue;
                        let message = errors[field][0];
                        let inputField = $('#'+field);

                        inputField.closest('.form-group').find('.form-control').addClass('is-invalid');
                        inputField.closest('.form-group').find('.invalid-feedback').html(message);
                    }

                }
            },
            success: function (result) {
                if (result.hasOwnProperty('redirect')) {
                    document.location.href = result.redirect;
                }
            }
        });
    }
});
